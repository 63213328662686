<template>
  <div>
    <div v-if="!sessionKey || sessionKey == ''">
      <div class="grid h-screen place-items-center bg-mpiwg-green">
        <div
          class="w-[520px] px-8 py-6 m-auto bg-white dark:bg-charcoal shadow-lg border-slate-200 rounded dark:text-white"
        >
          <div>
            <form @submit.prevent="getSession">
              <div class="mt-4">
                <div class="grid grid-cols-12 mb-8 mt-8">
                  <div class="col-span-1"></div>
                  <div class="col-span-3 place-content-right">
                    <img
                      class="object-contain dark:invert w-24 place-self-right"
                      src="images/mpiwg_logo_single.png"
                    />
                  </div>
                  <div class="col-span-7 text-l flex h-full">
                    <div class="place-self-center">
                      <div class="w-full font-bold pl-4">
                        MAX-PLANCK INSTITUTE FOR THE HISTORY OF SCIENCE
                      </div>
                      <span class="w-full pl-4 italic"
                        >Heavens in your Hand</span
                      >
                    </div>
                  </div>
                  <div class="col-span-1"></div>
                </div>
                <div>
                  <p>The <i>Heavens Map</i> is a tailor-made interactive platform designed to map the extensive collection of entries in the <a target="_blank" href="https://www.mpiwg-berlin.mpg.de/research/projects/image-database-visualization-of-the-heavens"><i class="underline">VoH Image Database</i></a> and facilitate easy access to and navigation of its rich material. Scholars, researchers, and enthusiasts can explore thousands of artifacts with astral imagery across time and space.</p>
                  <br/>
                  <p>To sign in, please use your <i>VoH Image Database</i> credentials, which you can obtain by contacting <a target="_blank" class="underline" href="mailto:pbelouin@mpiwg-berlin.mpg.de">Pascal Belouin</a>.</p> 
                </div>
                <div
                  v-if="error"
                  class="text-sm rounded shadow p-4 mt-2 bg-yellow-200 bg-opacity-30 border border-yellow-400"
                >
                  We couldn't sign you in. Please make sure your credentials are
                  correct, and that you are using your
                  <a
                    class="underline"
                    href="https://maxplanck.resourcespace.com/"
                    target="_blank"
                    >ResourceSpace</a
                  >
                  credentials. <br />
                  <br />If the error persists, please contact our administrator
                  at
                  <a href="mailto:pbelouin@mpiwg-berlin.mpg.de"
                    >pbelouin@mpiwg-berlin.mpg.de</a
                  >.
                </div>
                <label class="block mt-4 dark:color-white" for="email"
                  >Username</label
                >
                <input
                  type="text"
                  placeholder="Username"
                  v-model="username"
                  ref="username"
                  class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-grey-600 dark:bg-light-charcoal dark:border-slate-500"
                />
                <div class="mt-4">
                  <label class="block">Password</label>
                  <input
                    type="password"
                    placeholder="Password"
                    v-model="password"
                    class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 dark:bg-light-charcoal dark:border-slate-500"
                  />
                </div>
                <div class="flex items-baseline justify-between mt-6">
                  <button
                    class="bg-white hover:bg-gray-100 text-gray-800 text-xs py-2 px-4 border border-gray-400 rounded shadow mr-2 dark:text-white  dark:border-light-charcoal dark:hover:bg-light-charcoal dark:bg-dark-charcoal"
                    type="submit"
                  >
                    Login
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div id="ui" v-if="sessionKey">
      <Visualizer @signout="signOut" :session-key="sessionKey" :theme="theme" />
    </div>
  </div>
</template>

<script>
import Visualizer from "./components/Visualizer.vue";
import * as utils from "./lib/Utilities.js";

export default {
  name: "App",
  components: {
    Visualizer,
  },
  data() {
    return {
      theme: "",
      username: "",
      password: "",
      sessionKey: "",
      error: false,
    };
  },
  mounted() {
    this.$refs.username.focus();
    this.sessionKey = localStorage.sessionKey;
    this.theme = localStorage.theme;
    if (this.theme == "") {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        this.theme = "dark";
        document.documentElement.classList.add("dark");
      } else {
        this.theme = "light";
        document.documentElement.classList.remove("dark");
      }
    }
    if (this.theme == "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  },
  watch: {
    sessionKey(value) {
      if (value != "") {
        this.theme = localStorage.theme;
      }
    },
  },
  methods: {
    signOut() {
      console.log("logout");
      this.sessionKey = "";
      this.username = "";
      localStorage.sessionKey = this.sessionKey;
      localStorage.username = this.username;
      this.$nextTick(() => {
        this.$refs.username.focus();
      });
    },
    async getSession() {
      const query = `function=login&username=${this.username}&password=${this.password}`;
      const loginResponse = await this.axios.get(`${utils.host}/?${query}`);
      if (!loginResponse.data) {
        this.error = true;
      } else {
        this.sessionKey = loginResponse.data;
        localStorage.sessionKey = this.sessionKey;
        localStorage.username = this.username;
        this.password = "";
        this.error = false;
      }
    },
  },
};
</script>

<style></style>
